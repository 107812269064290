/* This example requires Tailwind CSS v2.0+ */
import { MailIcon, PhoneIcon } from '@heroicons/react/solid'
import Banner from '../assets/banner.jpg'
const profile = {
  avatar: 'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80'
}

export default function About() {
  return (
    <div>
      <div>
        <img className="h-32 w-full object-cover lg:h-48" src={Banner} alt="" />
      </div>
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
          {/* <div className="flex">
            <img className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32" src={profile.avatar} alt="" />
          </div> */}
          <div className="flex">
            <span className="inline-block h-24 w-24 rounded-full overflow-hidden bg-gray-100 ring-4 ring-white sm:h-32 sm:w-32">
              <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
          </div>
          <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div className="sm:hidden md:block mt-6 min-w-0 flex-1">
              <h1 className="text-2xl font-bold text-gray-900 truncate">Christopher Valdesuso</h1>
              <p className="text-sm font-medium text-gray-500">Musician, Woodworker, Luthier</p>
            </div>
            <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
              <a href="mailto:chris@tallerberunda.com">
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  <MailIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <span>Message</span>
                </button>
              </a>
              <a href="callto:7874544960">
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  <PhoneIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <span>Call</span>
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="hidden sm:block md:hidden mt-6 min-w-0 flex-1">
          <h1 className="text-2xl font-bold text-gray-900 truncate">Christopher Valdesuso</h1>
          <p className="text-sm font-medium text-gray-500">Musician, Woodworker, Luthier</p>
        </div>
      </div>
      <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">

        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <p>
            Chris has over 10 years of experience building and fixing guitars and basses.
            His journey started in <a href="https://www.galloupguitars.com/schools-of-lutherie-luthier.htm" target="_blank"><span className="text-indigo-700 font-bold">Galloup School of Lutherie</span></a> in 2012.
            This fueled his passion for woodworking, and eventually,
            he had the privilege of studying fine woodworking at <a href="https://www.vermontwoodworkingschool.com/?gclid=Cj0KCQjw0oyYBhDGARIsAMZEuMskWQSrE8UivAiQlIjOaAyN752nUHtGsjxL3rmVOVQGi-i8YEAjzz8aAmA9EALw_wcB" target="_blank"><span className="text-indigo-700 font-bold">Vermont Woodworking School</span></a>.
            Soon after concluding his woodworking studies,
            Chris started working in <a href="https://www.dowbuilt.com/services/woodshop" target="_blank"><span className="text-indigo-700 font-bold">Dowbuilt Woodshop</span></a> in Seattle, WA, where he improved his skills as a craftsman with attention to detail.
            Now Chris lives with his family in Puerto Rico where he dedicates his time to repairing guitars.
          </p>
        </div>
      </div>
    </div>
  )
}
