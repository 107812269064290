import { Link } from "react-router-dom"
import Bass from '../assets/bass.jpeg'

export default function Home() {
  return (
    <div className="bg-gray-50 flex-grow flex flex-col h-full">
      {/* Hero card */}
      <div className="relative">
        <div className="relative shadow-xl">
          <div className="absolute inset-0">
            <img
              className="h-full w-full object-cover"
              src={Bass}
              alt="Bass"
            />
            <div className="absolute inset-0 bg-indigo-500 mix-blend-multiply" />
          </div>
          <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <h1 className="text-center text-4xl font-bold tracking-tight sm:text-5xl sm:tracking-tight lg:text-6xl lg:tracking-tight">
              <span className="block text-white">Maximize your guitar’s true potential</span>
              <span className="block text-indigo-200">Improve tone & playability </span>
            </h1>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto text-center py-8 px-4 sm:px-6 lg:py-12 lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:tracking-tight text-indigo-600">
          Shop Hours
        </h2>
        <h3 className="mt-2 text-xl font-bold tracking-tight sm:text-2xl">
          Monday - Saturday
        </h3>
        <p className="mt-2 font-bold text-center text-xl sm:max-w-3xl">
          10:00 AM - 6:30 PM
        </p>
        <p className="mt-2 font-bold text-center text-xl sm:max-w-3xl">
          Appointment Only
        </p>
        <div className="mt-8 flex justify-center">
          <div className="inline-flex rounded-md shadow">
            <Link
              to="/contact"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

