/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/solid'

const services = [
  {
    name: 'Restringing',
    startingPrice: 25,
    includedFeatures: ['Basic cleaning'],
  },
  {
    name: 'Standard Setup',
    startingPrice: 65,
    includedFeatures: [
      'Basic cleaning',
      'Truss rod adjustment',
      'Bridge adjustment',
      'Pickup height adjusment',
      'Intonation',
      'Electronics cleaning'
    ],
  },
  {
    name: 'Full Setup',
    startingPrice: 120,
    includedFeatures: [
      'Basic cleaning',
      'Truss rod adjustment',
      'Bridge adjustment',
      'Pickup height adjusment',
      'Intonation',
      'Electronics cleaning',
      'Fret leveling and polishing'
    ],
  },
  {
    name: 'Refrets',
    startingPrice: 300,
    includedFeatures: [
      'Fretboard leveling',
      'Fret leveling',
      'Fret replacement',
      'Full Setup'
    ],
  },
  {
    name: 'Custom bone nuts',
    startingPrice: 80,
    includedFeatures: [
      'Custom bone instalation',
      'Restringing',
    ],
  },
  {
    name: 'Custom bone nuts + Standard Setup',
    startingPrice: 120,
    includedFeatures: [
      'Custom bone instalation',
      'Restringing',
      'Standard Setup'
    ],
  },
  {
    name: 'Pickup installation (Passive)',
    startingPrice: 45,
    includedFeatures: [
      'Pickup swap',
      'Electronics cleaning',
      '$10 for each additional pickup'
    ],
  },
  {
    name: 'Pickup installation (Active)',
    startingPrice: 75,
    includedFeatures: [
      'Pickup swap',
      'Electronics cleaning',
      '$15 for each additional pickup'
    ],
  }

]

export default function Services() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-center">Our Services</h1>
          <p className="mt-5 text-xl text-gray-500 sm:text-center">
            Prices are estimates and do not include the cost of strings and materials
          </p>
        </div>
        <div className="mt-8 space-y-4 sm:mt-12 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
          {services.map((tier) => (
            <div key={tier.name} className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
              <div className="p-6">
                <h2 className="text-lg leading-6 font-medium text-gray-900">{tier.name}</h2>
                <p className="mt-8">
                  <span className="text-4xl font-extrabold text-gray-900">${tier.startingPrice}</span>{' '}
                  <span className="text-base font-medium text-gray-500">starting</span>
                </p>
              </div>
              <div className="pt-6 pb-8 px-6">
                <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">What's included</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {tier.includedFeatures.map((feature) => (
                    <li key={feature} className="flex space-x-3">
                      <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                      <span className="text-sm text-gray-500">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

