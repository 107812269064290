import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Routes from './Routes'
function App() {
  return (
    <div className='flex flex-col h-screen'>
      <Navbar />
      <main className="flex-grow">
        <Routes />
      </main>
      <Footer />
    </div>
  );
}

export default App;
