import { Route, Switch, Redirect } from 'react-router-dom'
// pages
import Home from './pages/Home'
import Contact from './pages/Contact'
import About from './pages/About'
import Services from './pages/Services'
// Store

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/about" component={About} />
      <Route exact path="/services" component={Services} />
    </Switch>
  )
}

export default Routes
